import gql from 'graphql-tag'

export const MUTATION_GET_OR_CREATE_POWERBI_EMBED_TOKEN = gql`
  mutation GetOrCreatePowerBIEmbedToken($reportId: String!) {
    getOrCreatePowerBIEmbedToken(reportId: $reportId) {
      token
      expiresAt
    }
  }
`
