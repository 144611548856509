<template>
  <div id="pbiComponent" ref="pbiComponent" class="divHeight" />
</template>

<script>
import * as powerbi from 'powerbi-client'
import { DateTime } from 'luxon'

import { MUTATION_GET_OR_CREATE_POWERBI_EMBED_TOKEN } from '@/modules/external_bi/graphql'

export default {
  props: {
    reportId: {
      type: String,
      required: true
    },
    reuseLocalToken: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    componentId: null,
    powerbi: null
  }),
  computed: {
    dateToExpireToken() {
      return DateTime.now().minus({ minutes: 15 })
    },
    embedUrl() {
      return `https://app.powerbi.com/reportEmbed?reportId=${this.reportId}`
    }
  },
  async mounted() {
    this.powerbi = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory)
    const pbiComponent = this.$refs.pbiComponent
    this.powerbi.bootstrap(pbiComponent, { type: 'report' })

    this.embedOnComponent(pbiComponent)
  },
  methods: {
    async embedOnComponent(pbiComponent) {
      const embedToken = await this.getOrCreateEmbedToken()
      const TOKEN_TYPE = powerbi.models.TokenType.Embed
      const pbiReportConfig = {
        type: 'report',
        tokenType: TOKEN_TYPE,
        accessToken: embedToken,
        embedUrl: this.embedUrl
      }
      const report = this.powerbi.embed(pbiComponent, pbiReportConfig)

      // Clear any other loaded handler events
      report.off('loaded')

      // Clear any other rendered handler events
      report.off('rendered')

      // Clear any other error handler events
      report.off('error')

      // Handle embed errors
      report.on('error', function(event) {
        let errorMsg = event.detail
        console.error(errorMsg)
      })
    },
    async createNewEmbedToken() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_GET_OR_CREATE_POWERBI_EMBED_TOKEN,
          variables: {
            reportId: this.reportId
          },
          context: {
            uri: this.$microservicesUrls.external_bi
          }
        })

        localStorage.setItem('exbi_embed_token', JSON.stringify(data.getOrCreatePowerBIEmbedToken))

        return data.getOrCreatePowerBIEmbedToken.token
      } catch (err) {
        console.log('Failed to get data', err.message)
        this.$snackbar({ message: 'Falha ao gerar embed token', snackbarColor: '#F44336' })
      }
    },
    getOrCreateEmbedToken() {
      if (!this.reuseLocalToken) {
        return this.createNewEmbedToken()
      }
      // Load from local storage
      const embedToken = localStorage.getItem('exbi_embed_token')

      if (!embedToken) {
        return this.createNewEmbedToken()
      }

      // Check if token is valid
      const parsedEmbedToken = JSON.parse(embedToken)
      if (!parsedEmbedToken?.token) {
        return this.createNewEmbedToken()
      }

      // Check timestamp, if it is safe, use it
      if (DateTime.fromISO(parsedEmbedToken?.expiresAt) <= this.dateToExpireToken) {
        return this.createNewEmbedToken()
      }

      return parsedEmbedToken.token
    }
  }
}
</script>
<style scoped>
.divHeight {
  height: 92vh;
}
@media (max-width: 1366px) {
  .divHeight {
    height: 89vh;
  }
}
</style>
